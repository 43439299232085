import React from "react";

const updateTariff = ({ addProps, paramsLink }) => {
  const { software, checkoutLanguage } = addProps;
  const eventCategory = `${software ? software : "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return checkoutLanguage ? `/${checkoutLanguage}${link}` : link;
  };
  return {
    oa: {
      title: "Hot Products Subscription",
      subTitle: (
        <>
          <span>1,000+ Exclusive Amazon Products</span> Receive 19 of the top 1%
          profitable products delivered to you weekly! Each one is selected by
          AI out of 600+ million products.
        </>
      ),
      payTitle: "Pay $19.99",
      payPeriod: "/month",
      // discountStrikethroughText: "",
      discountText: "month",
      buyNow: {
        category: eventCategory,
        action: "BuyNow",
        path: updateLink("/checkout/?offerId=weekly_hot_products_monthly"),
        text: "Buy now",
      },
      learnMore: {
        show: false,
        category: eventCategory,
        action: "Learn more",
        path: "",
        text: "Learn more",
      },
      refund: (
        <p className="PriceList-Tariff__refund">10-day money-back guarantee</p>
      ),
    },
    bundle: {
      title: "Hot Products Subscription",
      subTitle: (
        <>
          <span>1,000+ Exclusive Amazon Products</span> Receive 19 of the top 1%
          profitable products delivered to you weekly! Each one is selected by
          AI out of 600+ million products.
        </>
      ),
      payTitle: "Pay $16.7",
      payPeriod: "/month",
      discountStrikethroughText: "$239.88",
      discountText: " $199.99 billed annually",
      buyNow: {
        category: eventCategory,
        action: "BuyNow",
        path: updateLink("/checkout/?offerId=weekly_hot_products_annual"),
        text: "Buy now",
      },
      learnMore: {
        show: false,
        category: eventCategory,
        action: "Learn more",
        path: "",
        text: "Learn more",
      },
      refund: (
        <p className="PriceList-Tariff__refund">10-day money-back guarantee</p>
      ),
    },
  };
};
export default updateTariff;
