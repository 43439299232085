import React from "react";

export const DESCRIPTION_MONTHLY_CONTENT = {
  disableDescriptionOnRight: true,
  disableDescriptionOnLeft: true
};
export const DESCRIPTION_YEARLY_CONTENT = {
  disableDescriptionOnRight: true,
  disableDescriptionOnLeft: true
};

export const SWITCH_CONTENT = {
  disableSwitch: true
};
export const TITLE_BLOCKS_CONTENT = {
  titlesMonthly: { oaTitle: "Monthly", bundleTitle: "Annual" },
  titlesYearly: { oaTitle: "Monthly", bundleTitle: "Annual" }
};